import React from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CustomPanel from '../../../../../common/CustomPanel';
import UploadImage from '../UploadImage';
import { selectCashRanges } from '../../../Application/utils/selectors';

function calculateDues(ranges, total) {
  const range = ranges.find((r) => total >= r.min && total <= r.max);
  let result = range.quotes.map((q) => ({
    quota: q,
    amount: Math.round((total / q) * 100) / 100,
    dueText: `${q} Cuota(s) de ${Math.round((total / q) * 100) / 100}`,
  }));
  return result;
}

export default function CashPayment({ total }) {
  const { control, setValue, clearError, errors } = useFormContext();
  const ranges = useSelector(selectCashRanges);
  const dues = calculateDues(ranges, total);

  /** Function called by UploadImage component, allow to set
   * image path on image value of react hook form.
   * @param data image data.
   * */
  const onChangeImage = (data) => {
    if (data) {
      clearError([
        'cashPayment.image',
        'cashPayment.imageName',
      ]);
      setValue([
        { 'cashPayment.image': data?.dataURL },
        { 'cashPayment.imageName': data?.file?.name },
      ]);
    }
  };

  const onRemoveImage = (onRemove) => {
    onRemove();
    setTimeout(() => {
      setValue([
        { 'cashPayment.image': '' },
        { 'cashPayment.imageName': '' },
      ]);
    }, 500);
  };

  return (
    <>
      <Grid item xs={12}>
        <CustomPanel withoutTitle subtitle="Transferencia / Deposito">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <UploadImage
                onChangeImage={onChangeImage}
                onRemoveImage={onRemoveImage}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container direction="column">
                <Grid item>
                  <Typography>
                    Selecciona una imagen de evidencia
                    de la transferencia o deposito.
                  </Typography>
                </Grid>
                <Grid item>
                  <Controller
                    as={(
                      <TextField />
                    )}
                    fullWidth
                    label="IMAGEN"
                    name="cashPayment.imageName"
                    control={control}
                    InputProps={{ readOnly: true }}
                    error={errors.cashPayment?.hasOwnProperty('imageName')}
                    helperText={errors.cashPayment?.imageName?.message}
                    defaultValue=""
                  />
                  <Controller
                    as={TextField}
                    name="cashPayment.image"
                    control={control}
                    type="hidden"
                    defaultValue=""
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomPanel>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          fullWidth
          error={errors.cashPayment?.hasOwnProperty('dues')}
        >
          <InputLabel>PAGO CUOTAS</InputLabel>
          <Controller
            as={(
              <Select>
                <MenuItem value=""><em>Seleccione..</em></MenuItem>
                {dues?.map((d, index) => (
                  <MenuItem
                    key={index}
                    value={JSON.stringify(d)}
                  >
                    {d.dueText}
                  </MenuItem>
                ))}
              </Select>
            )}
            labelId="dues-select"
            label="PAGO CUOTAS"
            name="cashPayment.dues"
            control={control}
            defaultValue=""
          />
          <FormHelperText>
            {errors.cashPayment?.dues?.message}
          </FormHelperText>
        </FormControl>
      </Grid>
    </>
  );
}

CashPayment.propTypes = {
  total: PropTypes.number.isRequired,
};
